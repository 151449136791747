import * as React from "react";

import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { LockOutlined } from "@ant-design/icons";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CardFooter, FormSubtitle, FormTitle, Button } from "@imperocom/ui";
import { InputRef, Input } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";

import { SwitchToTwoFactor } from "anon/loginTypeSwitch";
import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import { AjaxManager } from "utils/ajax";
import { IMPERO_WWW_AUTHENTICATION_LOGIN, IMPERO_WWW_AUTHENTICATION_SETUP_AND_SMS, IMPERO_WWW_AUTHENTICATION_SMS } from "utils/auth";
import { t } from "utils/i18n";
import { loginRedirectToRoot } from "utils/url";


import theme from "./theme.module.scss";


const FormItem = Form.Item;

class PasswordFormInternal extends React.Component<PasswordFormProps, PasswordFormState> {
    private passwordInput: React.RefObject<InputRef>;

    constructor(props: PasswordFormProps) {
        super(props);

        this.passwordInput = React.createRef();

        this.state = {
            status: { type: "DEFAULT" },
            requiresHumanVerification: props.requiresHumanVerification,
        };
    }

    componentDidMount = () => {
        if (this.passwordInput.current) { this.passwordInput.current.focus(); }
    };

    render = () => {
        const { rightSide, title, email, hasSSO, hasForgotPassword, onSwitchToSSO, onSwitchToEmailForm } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <FormCardContainer rightSide={rightSide}>
                <FormTitle title={title} />
                <FormSubtitle subtitle={t("Login_PasswordFormSubtitle", { email })} />
                <FormCard onSubmitForm={this.handleSubmit}>
                    <FormItem>
                        {getFieldDecorator("password", {
                            rules: [{ required: true, message: t("Login_PasswordRequired") }],
                        })(
                            <Input prefix={<LockOutlined className={theme.iconOpacity} />} type="password" placeholder={t("Login_Password")} ref={this.passwordInput} />
                        )}
                    </FormItem>
                    {this.renderHumanVerification()}
                    <FormItem className={theme.formItem}>
                        <Button
                            id="login-button"
                            type="primary"
                            htmlType="submit"
                            loading={this.state.status.type === "LOGGING_IN"} block>
                            {t("Login_LoginButton")}
                        </Button>
                    </FormItem>
                    {hasSSO ?
                        <FormItem className={theme.ssoButton}>
                            <Button id="switch-to-sso-button" type="link" onClick={onSwitchToSSO}>
                                {t("Login_UseDomainLogin")}
                            </Button>
                        </FormItem> :
                        null}
                </FormCard>

                { hasForgotPassword ?
                    <CardFooter>
                        <Link to={{ pathname: "/forgot-password", search: `?email=${encodeURIComponent(email)}` }} >{t("Login_ForgotPassword")}</Link>
                        <a onClick={onSwitchToEmailForm}>{t("Login_UseDifferentEmail")}</a>
                    </CardFooter> :
                    <CardFooter>
                        <a onClick={onSwitchToEmailForm}>{t("Login_UseDifferentEmail")}</a>
                    </CardFooter>
                }
            </FormCardContainer>
        );
    };

    renderHumanVerification = () => {
        const { getFieldDecorator } = this.props.form;
        const { requiresHumanVerification } = this.state;
        return requiresHumanVerification ? (
            <FormItem className={theme.hcaptchaContainer}>
                {getFieldDecorator("captcha", {
                    rules: [{ required: true }],
                })(
                    <HCaptcha
                        size="normal"
                        sitekey={IMPERO_HCAPTCHA_SITE_KEY}
                        onVerify={this.handleCaptchaVerify}
                    />
                )}
            </FormItem>
        ) :
            null;
    };

    handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: ValidatedFormValues) => {
            if (!err) {
                const { ajaxManager, email, apiRouteLogin } = this.props;
                const { captcha, password } = values;
                ajaxManager.ajax<{}, PasswordFormStatus>(
                    () => axios.post(apiRouteLogin, {
                        email,
                        password,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        human_verification_response: captcha,
                    }), {
                        component: this,
                        initialStatusValue: "DEFAULT",
                        getErrorStatus: () => ({ type: "DEFAULT" }),
                        inFlightStatus: { type: "LOGGING_IN" },
                        getSuccessStatus: () => { return { type: "DEFAULT" }; },
                        onErrorCode: {
                            401: e => {
                                const authType = e.response && e.response.headers["www-authenticate"] || undefined;
                                switch (authType) {
                                    case IMPERO_WWW_AUTHENTICATION_LOGIN:
                                        const requiresHumanVerification = e.response && e.response.headers["x-impero-requireshumanverification"] || undefined;
                                        this.props.form.setFields({
                                            password: {
                                                value: "",
                                                errors: [new Error(t("Login_UsernameOrPasswordIncorrect"))],
                                            },
                                        });
                                        if (requiresHumanVerification) {
                                            this.setState({
                                                requiresHumanVerification: true,
                                            });
                                        }
                                        break;
                                    case IMPERO_WWW_AUTHENTICATION_SETUP_AND_SMS:
                                        if (e.response) {
                                            this.props.onSwitchToSetupTwoFactor((e.response.data as any).challenge as string);
                                        }
                                        break;
                                    case IMPERO_WWW_AUTHENTICATION_SMS:
                                        if (e.response) {
                                            this.props.onSwitchToTwoFactor({ type: "DEFAULT", challenge: (e.response.data as any).challenge as string });
                                        }
                                        break;
                                    default:
                                        throw new Error(t("Login_UnknownAuthType") + ": " + authType);
                                }
                            },
                            403: () => {
                                this.props.form.validateFields((err: any) => {
                                    if (!err) {
                                        this.props.form.setFields({
                                            password: {
                                                value: "",
                                                errors: [new Error(t("Login_UnknownUser"))],
                                            },
                                        });
                                    }
                                });
                            },
                        },
                    })
                    .then(loginRedirectToRoot);
            }
        });
    };
    private handleCaptchaVerify = (captcha: string | null) => {
        console.log("captcha: ", captcha);
        this.props.form.setFieldsValue({
            captcha,
        });
        console.log("set");
        this.props.form.validateFields();
    };
}

interface ValidatedFormValues {
    password: string,
    captcha?: string,
}

interface PasswordFormProps extends FormComponentProps {
    rightSide: JSX.Element,
    title: string,
    email: string,
    hasSSO: boolean,
    hasForgotPassword: boolean,
    apiRouteLogin: string,
    requiresHumanVerification: boolean,
    onSwitchToSetupTwoFactor: (challenge: string) => void,
    onSwitchToTwoFactor: (params: SwitchToTwoFactor) => void,
    onSwitchToSSO: () => void,
    onSwitchToEmailForm: () => void,
    ajaxManager: AjaxManager,
}

type PasswordFormStatus = { type: "DEFAULT" } | { type: "LOGGING_IN" };
interface PasswordFormState {
    status: PasswordFormStatus,
    requiresHumanVerification: boolean,
    confirmDirty?: boolean,
}

const PasswordForm = Form.create<PasswordFormProps>()(PasswordFormInternal);

export {
    PasswordForm,
};
