import * as React from "react";


import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { UserOutlined } from "@ant-design/icons";
import { FormSubtitle, FormTitle, Button } from "@imperocom/ui";
import { InputRef, Input, Checkbox } from "antd";
import * as Cookies from "es-cookie";

import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import { isEmailValid } from "utils/form";
import { t } from "utils/i18n";

import theme from "./theme.module.scss";

const FormItem = Form.Item;
const REMEMBER_ME_COOKIE = "impero_remember_me";

class EmailFormInternal extends React.Component<EmailFormProps, EmailFormState> {
    private emailInput: React.RefObject<InputRef>;

    constructor(props: EmailFormProps) {
        super(props);
        this.emailInput = React.createRef();

        this.state = {
            status: { type: "DEFAULT" },
        };
    }

    componentDidMount = () => {
        if (this.emailInput.current) { this.emailInput.current.focus(); }
    };

    render = () => {
        const { title, rightSide, footer } = this.props;
        const { getFieldDecorator } = this.props.form;
        const rememberMeEmail = Cookies.get(REMEMBER_ME_COOKIE);

        return (
            <FormCardContainer rightSide={rightSide}>
                <FormTitle title={title} />
                <FormSubtitle subtitle={t("Login_EmailFormSubtitle")} />
                <FormCard onSubmitForm={this.handleSubmit}>
                    <FormItem>
                        {getFieldDecorator("email", {
                            rules: [{
                                required: true,
                                message: t("Login_UserNameRequired"),
                            }, {
                                validator: (_rule: any, value: string | undefined, callback: any) => {
                                    if (!isEmailValid(value)) {
                                        callback(t("Login_UserNameRequired"));
                                    } else {
                                        callback();
                                    }
                                },
                            }],
                            initialValue: rememberMeEmail,
                        })(
                            <Input prefix={<UserOutlined className={theme.iconOpacity} />} placeholder={t("Login_UserName")} ref={this.emailInput} />
                        )}
                    </FormItem>
                    <FormItem className={theme.rememberMe}>
                        {
                            getFieldDecorator("rememberMe", {
                                valuePropName: "checked",
                                initialValue: !!rememberMeEmail,
                            })(
                                <Checkbox>
                                    {t("Login_RememberMe")}
                                </Checkbox>
                            )
                        }
                        <Button
                            id="email-next-button"
                            type="primary"
                            htmlType="submit"
                            loading={this.state.status.type === "LOGGING_IN"} block>
                            {t("Login_EmailFormButton")}
                        </Button>
                    </FormItem>
                </FormCard>
                {footer}
            </FormCardContainer>
        );
    };

    handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: ValidatedFormValues) => {
            if (!err) {
                const { email, rememberMe } = values;
                const { onSubmitEmail } = this.props;
                // This used to be done after the organization login options were fetched, but there is
                // probably no issue doing this before.
                if (rememberMe) {
                    Cookies.set(REMEMBER_ME_COOKIE, email, { sameSite: "lax", secure: true, expires: 30 });
                } else {
                    Cookies.remove(REMEMBER_ME_COOKIE);
                }
                onSubmitEmail(email, this);
            }
        });
    };
}

interface ValidatedFormValues {
    email: string,
    rememberMe: boolean,
}

interface EmailFormProps extends FormComponentProps {
    rightSide: JSX.Element,
    title: string,
    footer?: JSX.Element,
    onSubmitEmail: (email: string, component: EmailFormInternal) => void,
}

type EmailFormStatus = { type: "DEFAULT" } | { type: "LOGGING_IN" };
interface EmailFormState {
    status: EmailFormStatus,
}

const EmailForm = Form.create<EmailFormProps>()(EmailFormInternal);

export {
    type EmailFormProps,
    type EmailFormStatus,
    EmailForm,
};
